<template>
  <b-modal
    id="modal-doctor"
    ref="refModal"
    title="Bác sĩ"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    scrollable
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                1. Thông tin chung
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="avatar"
              rules="required"
            >
              <image-view
                v-model="itemLocal.avatar"
                label="Hình ảnh đại diện"
                align="center"
                :src="itemLocal.avatar"
                :width="100"
                :height="100"
                required
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="full_name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Họ tên <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="full_name"
                  v-model="itemLocal.full_name"
                  placeholder="Nguyễn Nam Phong"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Giới tính"
              rules="required"
            >
              <select-gender
                v-model="itemLocal.gender"
                required
                :default-value="itemLocal.gender"
                label="Giới tính"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>

          <!-- Phone -->
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="phone"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  SĐT <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="phone"
                  v-model="itemLocal.phone"
                  placeholder="0888666888"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Email -->
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="email"
              rules="required|email"
            >
              <b-form-group>
                <template v-slot:label>
                  Email <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="email"
                  v-model="itemLocal.email"
                  placeholder="doctor@homedoc.vn"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- submit -->
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                2. Thông tin công việc
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Nơi làm việc <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="workplace"
                  v-model="itemLocal.workplace"
                  placeholder="Bệnh viện Bạch Mai"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="qualification"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Bằng cấp
                </template>
                <b-form-input
                  id="qualification"
                  v-model="itemLocal.qualification"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="6">

            <validation-provider
              #default="validationContext"
              name="Khoa"
              rules="required"
            >

              <select-department
                v-model="itemLocal.specialist"
                :default-value="itemLocal.specialist"
                label="Chọn khoa"
                required
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <select-specialist
              v-model="itemLocal.sub_specialist"
              :default-value="itemLocal.sub_specialist"
              label="Chuyên khoa"
              :department="itemLocal.specialist"
            />
          </b-col>
          <b-col cols="6">

            <validation-provider
              #default="validationContext"
              name="Khu vực"
              rules="required"
            >

              <select-province
                v-model="itemLocal.province"
                :default-value="itemLocal.province"
                required
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="6">

            <validation-provider
              #default="validationContext"
              name="Cơ sở"
              rules="required"
            >
              <select-facility
                v-model="itemLocal.medical_facility"
                :default-value="itemLocal.medical_facility"
                required
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <template v-slot:label>
                Số năm kinh nghiệm <span class="text-danger">(*)</span>
              </template>
              <spin-button
                v-model="itemLocal.experience_years"
                :min="1"
                :step="1"
                :default-value="itemLocal.experience_years"
              />
            </b-form-group>

          </b-col>
          <b-col cols="12">
            <b-form-group label="Mô tả">
              <b-form-textarea
                id="description"
                v-model="itemLocal.description"
                placeholder="Mô tả"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                3. Tài khoản
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group>
              <template v-slot:label>
                Thứ tự hiển thị <span class="text-danger">(*)</span>
              </template>
              <spin-button
                v-model="itemLocal.order"
                :min="1"
                :step="1"
                :default-value="itemLocal.order"
              />
            </b-form-group>

          </b-col>
          <b-col
            cols="6"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.is_personal_doctor"
                name="check-button"
                switch
                inline
              >
                Bác sĩ của tôi?
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.is_family_doctor"
                name="check-button"
                switch
                inline
              >
                Bác sĩ gia đình?

              </b-form-checkbox>
              <feather-icon
                v-b-tooltip.hover.v-warning
                v-b-modal.prepaid-detail-modal
                title="Bác sĩ gia đình là bác sĩ có thể khám ngay cho bệnh nhân (bệnh nhân request Khám ngay thì hệ thống sẽ chỉ trả về những bác sĩ này)"
                class="mr-25 text-warning"
                icon="HelpCircleIcon"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Trạng thái tài khoản"
              rules="required"
            >

              <select-user-status
                v-model="itemLocal.status"
                :default-value="itemLocal.status"
                label="Trạng thái tài khoản"
                required
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col
            v-if="!itemLocal._id"
            cols="6"
          >
            <!-- password -->
            <b-form-group
              label="Mật khẩu mới"
            >
              <template
                v-slot:label
              >
                Mật khẩu mới
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mật khẩu mới"
                vid="password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="itemLocal.password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!itemLocal._id"
            cols="6"
          >
            <!-- confirm password -->
            <b-form-group
              label="Xác nhận mật khẩu"
            >
              <template
                v-slot:label
              >
                Xác nhận mật khẩu
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Xác nhận mật khẩu"
                vid="confirmPassword"
                rules="required|password|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="confirmPassword"
                    v-model="itemLocal.confirmPassword"
                    class="form-control-merge"
                    :type="passwordConfirmFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="confirmPassword"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordConfirmToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordConfirmVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BInputGroupAppend,
  BInputGroup,
  BFormTextarea,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectProvince from '@/views/components/SelectProvince.vue';
import SelectGender from '@/views/components/SelectGender.vue';
import SelectDepartment from '@/views/components/SelectDepartment.vue';
import SelectFacility from '@/views/components/SelectFacility.vue';
import SelectSpecialist from '@/views/components/SelectSpecialist.vue';
import SpinButton from '@/views/components/SpinButton.vue';
import SelectUserStatus from '@/views/components/SelectUserStatus.vue';
import ImageView from '@/views/components/ImageView.vue';
import { togglePasswordConfirmVisibility } from '@/layouts/mixins/forms';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useDoctorModal from './useDoctorModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormTextarea,
    BFormCheckbox,
    SpinButton,

    SelectProvince,
    SelectGender,
    SelectDepartment,
    SelectFacility,
    SelectSpecialist,
    SelectUserStatus,
    ImageView,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useDoctorModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
