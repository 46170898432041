<template>
  <b-form-group
    label="Cơ sở"
    :state="state"
  >
    <template v-slot:label>
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="facility"
      v-model="facility"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="facilities"
      placeholder="Chọn cơ sở"
      @input="itemChanged"
      @open="onOpen"
    ><!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        Không có dữ liệu
      </template></v-select>
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Cơ sở',
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      facility: this.defaultValue,
      facilities: [],
    };
  },
  created() {
    this.fetchFacilities();
  },
  methods: {
    itemChanged() {
      this.$emit('input', this.facility);
    },
    fetchFacilities() {
      useJwt.getFacilities({ start: 0, length: 1000 }).then(res => {
        this.facilities = res.data.data;
      });
    },
    onOpen() {
      if (this.facilities.length === 0) {
        this.fetchFacilities();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
