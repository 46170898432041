<template>
  <b-modal
    :id="id"
    :ref="refModal"
    title="Kinh nghiệm làm việc"
    ok-only
    scrollable
    size="lg"
    ok-title="Đóng"
    ok-variant="secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
    @ok="resetModal"
  >
    <div class="exp-list-container">
      <b-button
        v-b-modal.modal-exp
        variant="primary"
        @click="onEditItem(null)"
      >Thêm
      </b-button>
      <vue-good-table
        class="pt-2"
        :columns="columns"
        :rows="data"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: is_represent -->
          <span v-if="props.column.field === 'title'">
            <span>{{ props.row.title }}</span>
            <small><br>{{ props.row.content }}</small>
          </span>

          <span v-else-if="props.column.field === 'time'">
            {{ resolveTimeDuration(props.row) }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <table-actions
              name="Khách hàng"
              modal="modal-exp"
              @on-delete="onDelete(props.row)"
              @on-edit="onEditItem(props.row)"
            />
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <div slot="emptystate">
          <div class="text-center text-muted">
            Không có bản ghi nào!
          </div>
        </div>
      </vue-good-table>
    </div>
    <exp-modal
      :item="item"
      @refetch-data="fetchData"
    />

  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
} from 'bootstrap-vue';
import TableActions from '@/views/components/TableActions.vue';
import { VueGoodTable } from 'vue-good-table';
import useExpListModal from './useExpListModal';
import ExpModal from '../modal/ExpModal.vue';

export default {
  components: {
    BModal,
    BButton,
    ExpModal,
    VueGoodTable,
    TableActions,
  },
  props: {
    doctor: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: 'modal-exp-list',
    },
  },

  setup(props) {
    const {
      refModal,
      data,
      item,
      doctorItem,
      onDelete,
      resetItemLocal,
      resetModal,
      onOpen,
      onEditItem,
      resolveTimeDuration,
      fetchData,
    } = useExpListModal(props);

    const columns = [
      {
        label: 'Thời gian',
        field: 'time',
        sortable: false,
        width: '150px',
      },
      {
        label: 'Mô tả',
        field: 'title',
        sortable: false,
      },
      {
        label: 'Thao tác',
        field: 'action',
        sortable: false,
        width: '100px',
      },

    ];

    return {
      columns,
      refModal,
      data,
      item,
      doctorItem,
      onDelete,
      resetItemLocal,
      resetModal,
      onOpen,
      onEditItem,
      resolveTimeDuration,
      fetchData,
    };
  },
};
</script>
