var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:_vm.refModal,attrs:{"id":_vm.id,"title":"Học vấn","ok-only":"","scrollable":"","size":"lg","ok-title":"Đóng","ok-variant":"secondary","hide-header-close":true,"no-close-on-backdrop":""},on:{"show":_vm.onOpen,"hidden":_vm.resetModal,"ok":_vm.resetModal}},[_c('div',{staticClass:"education-list-container"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-education",modifiers:{"modal-education":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onEditItem(null)}}},[_vm._v("Thêm ")]),_c('vue-good-table',{staticClass:"pt-2",attrs:{"columns":_vm.columns,"rows":_vm.data,"select-options":{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'title')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.title))]),_c('small',[_c('br'),_vm._v(_vm._s(props.row.content))])]):(props.column.field === 'time')?_c('span',[_vm._v(" "+_vm._s(_vm.resolveTimeDuration(props.row))+" ")]):(props.column.field === 'action')?_c('span',[_c('table-actions',{attrs:{"name":"học vấn","modal":"modal-education"},on:{"on-delete":function($event){return _vm.onDelete(props.row)},"on-edit":function($event){return _vm.onEditItem(props.row)}}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" Không có bản ghi nào! ")])])])],1),_c('education-modal',{attrs:{"item":_vm.item},on:{"refetch-data":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }