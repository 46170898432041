import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import doctorStoreModule from '../doctorStoreModule';

export default function useDoctorList() {
  const APARTMENT_STORE_MODULE_NAME = "doctor";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, doctorStoreModule);
  }

  // // UnRegister on leave
  // onUnmounted(() => {
  //   if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
  //     store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
  //   }
  // });
  // Use toast
  const toastification = toast();

  const userData = ref(JSON.parse(localStorage.getItem('userData')));

  const blankItem = {
    avatar: '',
    full_name: '',
    phone: '',
    email: '',
    gender: null,
    order: 1,
    workplace: '',
    qualification: '',

    specialist: null,
    sub_specialist: null,
    medical_facility: null,
    province: null,
    experience_years: 1,
    description: '',

    status: null,
    is_family_doctor: false,
    is_personal_doctor: false,
    password: '',
    confirmPassword: '',
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Thao tác',
      field: 'action',
      width: '100px',
      tdClass: 'text-center',
      sortable: false,
      data: '_id',
      searchable: true,
      orderable: true,
    },
    {
      label: 'Bác sĩ',
      field: 'doctor',
      data: 'full_name',
      searchable: true,
      orderable: true,
    },
    {
      label: 'Cơ sở khám',
      field: 'medical_facility.name',
      data: 'medical_facility.name',
      searchable: true,
      orderable: true,
      hidden: userData.value.role.name === 'medical_facility',
    },
    {
      label: 'Khoa',
      field: 'specialist.name',
      data: 'specialist.name',
      searchable: true,
      orderable: true,
    },
    // {
    //   label: 'Bác sĩ cá nhân?',
    //   field: 'is_personal_doctor',
    //   tdClass: 'text-center',
    //   thClass: 'text-center',
    //   width: '110px',
    //   type: 'boolean',
    //   data: 'is_personal_doctor',
    //   searchable: true,
    //   orderable: true,
    // },
    {
      label: 'Trạng thái',
      field: 'status',
      tdClass: 'text-center',
      thClass: 'text-center',
      width: '110px',
      type: 'boolean',
      data: 'status',
      searchable: true,
      orderable: true,
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  const status = ref(null);
  const specialist = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    start: 0,
    length: 10,
    status: "-1",
    draw: "1",
    specialist: "-1",
    subSpecialist: "-1",
    search: {
      value: '',
      regrex: false,
    },
    order: [
      {
        dir: 'desc',
        column: 0,
      },
    ],
    columns: columns.map(obj => ({ data: obj.data, orderable: obj.orderable, searchable: obj.searchable })),
  });

  //   API Call

  const fetchDoctors = () => {
    store
      .dispatch('doctor/fetchDoctors', serverParams.value)
      .then(response => {
        const { data, recordsTotal } = response.data;
        totalRecords.value = recordsTotal;
        rows.value = data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchDoctors();
  };
  const importData = file => {
    const formData = new FormData();
    formData.append('file', file);
    store
      .dispatch('apartment/importApartments', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('apartment/exportApartments', serverParams.value)
      .then(response => {
        console.log(response.data);
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteApartments = apartments => {
    store
      .dispatch('apartment/deleteApartments', {
        ids: apartments.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onEditItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ start: (params.currentPage - 1) * serverParams.value.length });
  };

  const onPerPageChange = params => {
    updateParams({ length: params.currentPerPage });
  };

  const onSortChange = params => {
    const column = columns.map(_obj => _obj.field).indexOf(params[0].field);

    updateParams({
      order: [{
        dir: params[0].type,
        column,
      }],
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ search: { value: val } });
  });
  watch(status, val => {
    updateParams({ status: val ? val.value : "-1" });
  });
  watch(specialist, val => {
    updateParams({ specialist: val ? val._id : "-1" });
  });

  // UI
  const resolveStatusVariant = val => {
    if (val === 'active') return { variant: 'primary', title: "Kích hoạt", icon: 'SmileIcon' };
    if (val === 'inactive') return { variant: 'warning', title: "Chưa kích hoạt", icon: 'MehIcon' };
    return { variant: 'danger', title: "Khóa", icon: 'FrownIcon' };
  };

  const resolveFamilyDoctorVariant = val => {
    if (val === true) return { variant: 'primary', title: "Có", icon: 'CheckIcon' };
    return { variant: 'danger', title: "Không", icon: 'MinusIcon' };
  };

  const getAvatarURL = avatar => {
    const arr = avatar.split('-');
    arr.splice(-1);
    const result = `https://vntelehealth.s3.ap-southeast-1.amazonaws.com/upload/${encodeURIComponent(arr.join('-'))}.jpeg`;
    console.log(result);
    return result;
  };

  return {
    analytics,
    item,
    columns,
    rows,
    specialist,
    status,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    getAvatarURL,
    fetchData,
    deleteApartments,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    importData,
    exportData,
    resolveStatusVariant,
    resolveFamilyDoctorVariant,
  };
}
